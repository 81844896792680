import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../../components/seo';
import Stepone from '../../components/FormStepfour';
import { ResetCSS } from '../../common/src/assets/css/style';
import { GlobalStyle } from '../../containers/Saas/saas.style';

const FormStep4 = () => {

  const Data = useStaticQuery(graphql`
    query {
      allContentfulPages(filter: {slug: {eq: "offerte-aanvragen"}}) {
        edges {
          node {
            title
            slug
            metaTitle
            metaKeywords
            metaDescription
          }
        }
      }

      allContentfulBlockSetting {
        edges {
          node {
            homepageFormTitle
            formImages {
              fluid(maxWidth: 100){
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      allContentfulForm {
        nodes {
          id
          formPageMetaTitle
          step2Active
          step3Active
          step4Active
          step5Active
          step6Active
          step6ButtonText
          step3TextPlaceholder
          step2SlugUrl
          step3SlugUrl
          step4SlugUrl
          step5SlugUrl
          step6SlugUrl
          step2MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step1Description {
            json
          }
          redirectUrlOnSuccess
          logo {
            fluid(maxWidth: 200){
              ...GatsbyContentfulFluid_withWebp
            }
          }
          step1MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step2Description {
            json
          }
          step3Description {
            json
          }
          step4MultipleOptions {
            optionLabel {
              json
            }
            optionValue
          }
          step4Description {
            json
          }
          step5Text {
            json
          }
        }
      }

    }
  `);

  const metaData = Data.allContentfulPages.edges[0].node;

  const sideBarData = Data.allContentfulBlockSetting.edges[0].node;

  const formData = Data.allContentfulForm.nodes[0];

  return (
    <>
      <SEO
          title={metaData.metaTitle}
          description={metaData.metaDescription}
        />
        <ResetCSS />
        <GlobalStyle />
        <div className="form_container anvragen_page">
        <Stepone data={metaData} sidebar={sideBarData} formData={formData} />
      </div>
      
    </>
  );

}

export default FormStep4;
